var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('modal', {
    ref: "modal",
    attrs: {
      "title": _vm.title,
      "width": 600,
      "scrollOutside": ""
    }
  }, [_c('PaymentForm', _vm._b({
    on: {
      "complete": _vm.onComplete,
      "state": function ($event) {
        _vm.formState = $event;
      }
    }
  }, 'PaymentForm', _vm.formProps, false))], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };